<template>
  <div class="main">
    <div class="d-flex justify-content-center" v-if="loadingSpinner">
      <b-spinner
        variant="success"
        label="Spinning"
        style="width: 3rem; height: 3rem"
      ></b-spinner>
    </div>
    <div v-if="!loadingSpinner">
      <b-card class="my-2">
        <b-row>
          <b-col>
            <h2 class="text-primary m-0 p-0">Product manufactured inhouse?</h2>
          </b-col>
          <b-col cols="12" class="d-flex align-items-center justify-content-start my-1">
            <label for="check-button" class="mr-2 text-primary">No</label>
            <b-form-checkbox
              checked="false"
              name="check-button"
              switch
              inline
              v-model="product.inHouseManufactured"
            >
            </b-form-checkbox>
            <label for="check-button" class="text-primary">Yes</label>
          </b-col>
          <b-col>
            <h3></h3>
          </b-col>
          <div class="d-flex justify-content-center">
            <b-spinner large v-if="loading" class="mx-2" />
            <span v-if="loading">Loading...</span>
          </div>
        </b-row>
      </b-card>
      <form-wizard
        color="#B1D06C"
        :title="null"
        :subtitle="null"
        shape="square"
        finish-button-text="Submit"
        back-button-text="Previous"
        class="mb-3"
        @on-complete="formSubmitted"
        ref="formWiz"
        v-if="product.inHouseManufactured == true"
      >
        <!-- Search details tab -->
        <tab-content title="Details">
          <AddProductBuy @onchange="detailsChanged" />
        </tab-content>
        <tab-content title="Bill Of Materials">
          <AddBOM @onchange="bomChanged" />
        </tab-content>
        <tab-content title="Operation Steps">
          <buildprocess @onchange="operationChanged" />
        </tab-content>
        <tab-content title="Pricing">
          <ConfirmProduct :Product="product" @onchange="pricingChanged" />
        </tab-content>
        <tab-content title="Quality Control">
          <OtherCosts
            :Product="product"
            :Items="qualityItems"
            @onchange="qualityChanged"
          />
        </tab-content>
        <tab-content title="Finish">
          <b-container class="d-flex justify-content-center last_step flex-column">
            <img src="./check-mark.svg" style="height: 200px" alt="" />
            <h2 class="text-center mt-3 text-primary">Submit and Confirm</h2>
          </b-container>
        </tab-content>
      </form-wizard>
      <form-wizard
        color="#B1D06C"
        :title="null"
        :subtitle="null"
        shape="square"
        finish-button-text="Submit"
        back-button-text="Previous"
        class="mb-3"
        @on-complete="formSubmitted"
        ref="formWiz"
        v-if="product.inHouseManufactured == false"
      >
        <!-- Search details tab -->
        <tab-content title="Details">
          <AddProduct @onchange="detailsChanged" />
        </tab-content>
        <tab-content title="Pricing">
          <ConfirmProduct :Product="product" @onchange="pricingChanged" />
          <!-- <b-container class="d-flex justify-content-center last_step flex-column">
                    <img src="./check-mark.svg" style="height: 200px;" alt="">
                    <h2 class="text-center mt-3 text-primary">Submit and Confirm</h2>
                </b-container> -->
        </tab-content>
        <!-- <tab-content title="Quality Control">
                <OtherCosts :Product="product" @onchange="qualityChanged" />
            </tab-content> -->
        <tab-content title="Finish">
          <b-container class="d-flex justify-content-center last_step flex-column">
            <img src="./check-mark.svg" style="height: 200px" alt="" />
            <h2 class="text-center mt-3 text-primary">Submit and Confirm</h2>
          </b-container>
        </tab-content>
      </form-wizard>
    </div>
  </div>
</template>

<script>
import ConfirmProduct from "./ConfirmProduct.vue";
import AddProduct from "./addproduct.vue";
import buildprocess from "./buildprocess.vue";
import AddProductBuy from "./AddProductBuy.vue";
import OtherCosts from "./OtherCosts.vue";
import AddBOM from "./AddBOM.vue";
import { FormWizard, TabContent } from "vue-form-wizard";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { mapActions, mapGetters } from "vuex";
import {
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
  BContainer,
  BCard,
  BSpinner,
} from "bootstrap-vue";
import { codeIcon } from "./LeadsWizard.js";

export default {
  components: {
    FormWizard,
    BContainer,
    buildprocess,
    AddBOM,
    BButton,
    BFormCheckbox,
    BCard,
    TabContent,
    BRow,
    OtherCosts,
    AddProduct,
    BCol,
    AddProductBuy,
    ConfirmProduct,
    ToastificationContent,
    BSpinner,
  },
  data() {
    return {
      codeIcon,
      loadingSpinner: true,
      productFile: null,
      totalBomCost: 0,
      totalOperationCost: 0,
      qualityItems: [],
      product: {
        type: null,
        name: null,
        code: null,
        barcode: null,
        description: null,
        letterDescription: null,
        salePrice: null,
        purchasePrice: null,
        productionLimit: null,
        inHouseManufactured: true,
        markUpPercentage: null,
        saleTaxPercentage: null,
        lowStock: null,
        categoryID: null,
        billOfMaterials: [],
        operationSteps: [],
      },
    };
  },

  mounted() {
    setTimeout(() => {
      this.loadingSpinner = false;
    }, 5000);
  },
  methods: {
    //route pages to leads
    neckStep() {
      this.$refs.formWiz.nextTab();
    },
    detailsChanged(details) {
      this.product.type = details.type;
      this.product.name = details.name;
      this.product.code = details.code;
      this.product.barcode = details.barcode;
      this.product.description = details.description;
      this.product.letterDescription = details.letter ? details.letter : " ";
      this.product.productionLimit =
        details.productionLimit === "" ? null : details.productionLimit;
      this.product.categoryID = details.categoryId;
      this.product.supplierId = details.supplierId;
      this.product.uomid = details.uom;
      this.productFile = details.file;
      if (details.lowStock > 0 && details.lowStock != null && !isNaN(details.lowStock)) {
        this.product.lowStock = parseFloat(details.lowStock).toFixed(3);
        console.log("Product Low stock: ", this.product.lowStock);
      } else {
        console.log("Details Low stock: ", details.lowStock);
      }
    },
    bomChanged(boms) {
      let vm = this;
      vm.product.billOfMaterials = [];
      vm.totalBomCost = 0;
      boms.forEach(function (item, index) {
        vm.product.billOfMaterials.push({
          materialId: item.id,
          itemUomId: item.uom,
          quantity: item.qty,
          totalCost: item.totalCost,
          itemBOMOrder: index,
        });
        vm.totalBomCost += parseFloat(item.totalCost);
        console.log("Total BOM: ", vm.totalBomCost);
      });
      vm.product.purchasePrice =
        parseFloat(vm.totalBomCost) + parseFloat(vm.totalOperationCost);
    },

    operationChanged(operations) {
      let vm = this;
      let oldSteps = vm.product.operationSteps;
      vm.product.operationSteps = [];
      vm.totalOperationCost = 0;
      console.log("Steps: ", operations);
      operations.forEach(function (item, index) {
        vm.product.operationSteps.push({
          name: item.name,
          id: null,
          workstation: item.workstation,
          cycles: item.cycles,
          cycleHours: item.cycleHours,
          labourId: item.labourId,
          labourCost: item.labourCost || 0,
          equipmentId: item.equipmentId,
          duration: item.duration,
          equipmentCost: item.equipmentCost || 0,
          directCost: item.directCost || 0,
          inDirectCostName: item.indirectCostName || "N/A",
          indirectCost: parseFloat(item.indirectCost) || 0,
          totalCost: item.totalCost || 0,
          itemOperationOrder: index,
          operationQuality: vm.getOperationQuality(oldSteps, item.name),
          subOperationSteps: item.substeps.map((substep, index) => {
            return {
              labourId: substep.labourId,
              name: substep.name,
              workstation: substep.workstation,
              labourCost: substep.labourCost,
              indirectCost: substep.indirectCost,
              labourQuantity: substep.labourQty,
              cycles: substep.cycles,
              cycleHours: substep.cycleHours,
              directCost: substep.directCost,
              totalLabourCost: substep.totalLabourCost,
              totalEquipmentCost: substep.totalEquipmentCost,
              totalCost: substep.totalCost,
              InDirectCostName: "N/A",
              itemOperationOrder: index,
              itemSubOperationEquipment: substep.equipments.map((equipment, index) => {
                return {
                  equipmentId: equipment.equipmentId,
                  equipmentCost: equipment.equipmentCost,
                  equipmentQuantity: equipment.equipmentQty,
                  totalEquipmentCost: equipment.totalEquipmentCost,
                  itemOperationEquipmentOrder: index,
                };
              }),
            };
          }),
        });
        vm.totalOperationCost += item.totalCost;
      });
      vm.product.purchasePrice = (
        parseFloat(this.totalBomCost) + parseFloat(this.totalOperationCost)
      ).toFixed(2);
      vm.pricingChanged(vm.product);
      vm.refreshQualityItems(vm.product);
    },
    getOperationQuality(oldSteps, currentName) {
      let step = oldSteps.filter((obj) => {
        return obj.name === currentName;
      });
      if (step.length > 0) {
        return step[0].operationQuality;
      }
      return [];
    },
    refreshQualityItems(product) {
      let vm = this;
      vm.qualityItems = [];
      let serial = 1;
      product.operationSteps.forEach(function (step) {
        step.operationQuality.forEach(function (item) {
          vm.qualityItems.push({
            serial: serial,
            operationName: step.name,
            name: item.name,
            minValue: item.minValue,
            maxValue: item.maxValue,
          });
        });
      });
    },

    pricingChanged(product) {
      let markupAmount =
        parseFloat(product.purchasePrice || 0) *
        (parseFloat(product.markUpPercentage || 0) / 100);
      let priceBeforeTax = parseFloat(product.purchasePrice || 0) + markupAmount;
      let taxAmount = priceBeforeTax * (parseFloat(product.saleTaxPercentage || 0) / 100);
      product.salePrice = (priceBeforeTax + taxAmount).toFixed(2);
      this.product = product;
    },
    qualityChanged(product) {
      this.product = product;
    },
    ...mapActions("productModule", ["addProductAction"]),
    formSubmitted() {
      this.addProductAction({ product: this.product, file: this.productFile })
        .then(() => {
          this.success();
          this.$bvModal.hide("modal-1");
        })
        .catch((ex) => {
          this.error(ex.response.data);
          this.$bvModal.hide("modal-1");
        });
    },
    success() {
      this.$swal({
        title: "Products!",
        text: "You have successfully added the product!",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$router.push({ name: "product-list" });
        }
      });
    },
    error(data) {
      let msg = null;
      if (data) msg = this.getErrorString(data);
      this.$swal({
        title: "Error!",
        html: msg || " Failed!",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    getErrorString(data) {
      let msg = null;
      if (data.errors) {
        let errors = data.errors;
        msg = "<div>";
        msg += "<p>Cannot post your request due to the following errors:</p>";
        for (var prop in errors) {
          if (Object.prototype.hasOwnProperty.call(errors, prop) && prop !== "request") {
            msg += "<p>";
            msg += prop.replace("$.", "");
            msg += ": ";
            msg += errors[prop].toString().replace("$.", "");
            msg += "</p>";
          }
        }
        msg += "</div>";
      } else if (data.message) {
        msg = "<div>";
        msg += "<p>Cannot post your request due to the following errors:</p>";
        msg += data.message;
        msg += "</div>";
      }
      return msg;
    },
  },
  computed: {
    ...mapGetters("productModule", {
      loading: "loading",
    }),
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

<style scoped>
label {
  font-size: 1.714rem !important;
  font-weight: 500;
  margin-bottom: 0;
}
</style>
